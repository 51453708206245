import React, { useState } from "react";
import { ValidatePinModal } from "app/components/ValidatePinModal";
import Text from "app/components/Text";
import { CreatePinModal } from "../CreatePinModal";

interface Props {
  onSuccess(): void;
  onCancel(): void;
}

export const UpdatePinFlow = ({ onSuccess, onCancel }: Props) => {
  const [isValidatePinModalOpen, toggleValidatePinModal] = useState(true);
  const [validatedPin, setValidatedPin] = useState("");

  return (
    <>
      <ValidatePinModal
        isOpen={isValidatePinModalOpen}
        onValidated={pin => {
          toggleValidatePinModal(false);
          setValidatedPin(pin);
        }}
        onCancel={onCancel}
      >
        <Text fontWeight="700" fontSize="63px">
          Enter your PIN
        </Text>
        <Text my="64px" fontWeight="400" fontSize="14px">
          To update your PIN, please enter your existing PIN.
        </Text>
      </ValidatePinModal>
      <CreatePinModal
        isOpen={!!validatedPin}
        validatedPin={validatedPin}
        onSuccess={onSuccess}
        onCancel={onCancel}
      />
    </>
  );
};
