import React from "react";
import Img from "app/components/Img";
import Icon, {
  STEEZYLogo,
  STEEZYFamilyHorizontal,
  STEEZYFamilyVertical,
  StarRating4pt7,
} from "app/components/Icon";
import Flex from "app/components/Flex";
import env from "helpers/env";
import { FAMILY_ACTIONS } from "../constants";
import CheckeredBackground from "./checkered-background.svg";
import CircularBackground from "./circular-background.svg";
import {
  Video,
  BjolaH1,
  FamilyA,
  FamilyP1,
  FamilyP2,
  FamilyP3,
  FamilyH2,
  FamilyButton,
  FamilyButtonWhite,
  FamilyBullet,
} from "./styles";

export function LandingPage({ setFamilyAction }) {
  return (
    <Flex width="100%" flexDirection="column">
      <Flex width="100%" flexDirection={{ _: "column", md: "row" }}>
        <Flex flex="1" order={{ _: 2, md: 1 }}>
          <Flex flexDirection="column" p={{ _: "30px", md: "48px" }}>
            <STEEZYFamilyHorizontal width="100%" />
            <BjolaH1 color="familyBlue" mt="56px">
              Jam With the Fam, On-Demand.
            </BjolaH1>
            <FamilyP1 color="familyBlue" mt="20px">
              Our fun, immersive Dance Alongs are the perfect way for your
              family to bond while staying healthy — no dance experience
              required!
            </FamilyP1>
            <FamilyButton
              mt="60px"
              onClick={() => setFamilyAction(FAMILY_ACTIONS.ENABLE_FAMILY)}
            >
              Get Started
            </FamilyButton>
            <Flex
              alignItems="center"
              mt="32px"
              flexWrap="wrap"
              justifyContent="space-between"
            >
              <FamilyP2 color="familyPurple">
                Brought to you on the #1 dance app in the world.
              </FamilyP2>
              <FamilyP2 color="familyPurple">
                <Icon as={STEEZYLogo} height="8px" color="familyPurple" />
                <Icon
                  as={StarRating4pt7}
                  height="8px"
                  width="41px"
                  color="champYellow.4"
                  mx="8px"
                />
                | 12k Ratings
              </FamilyP2>
            </Flex>
          </Flex>
        </Flex>
        {/* @TODO: Replace src with video */}
        <Flex flex="1" order={{ _: 1, md: 2 }}>
          <Video autoPlay loop muted playsInline>
            <source
              src={`${env("PUBLIC_ASSET_PATH")}/onboarding/paywall-video.webm`}
              type="video/webm"
            />
            <source
              src={`${env("PUBLIC_ASSET_PATH")}/onboarding/paywall-video.mp4`}
              type="video/mp4"
            />
          </Video>
        </Flex>
      </Flex>
      <CheckeredBackground height="96px" />
      <Flex
        bg="familyPurple"
        p={{ _: "30px", md: "48px" }}
        flexDirection={{ _: "column", md: "row" }}
      >
        <Flex flex="1.5">
          <Img
            src={`${env("PUBLIC_ASSET_PATH")}/family/family-apps.png`}
            width="100%"
            height="auto"
            objectFit="contain"
          />
        </Flex>
        <Flex flexDirection="column" flex="1">
          <BjolaH1 color="white">
            Your Family&apos;s New Favorite Bonding Activity.
          </BjolaH1>
          <FamilyP1 color="white" mt="32px">
            <FamilyBullet>
              Jump into our convenient, at-home dance content anytime you want.
            </FamilyBullet>
            <FamilyBullet color="white">
              Build fun exercise into your family&apos;s weekly routine.
            </FamilyBullet>
            <FamilyBullet color="white">
              Make lifelong memories with your loved ones!
            </FamilyBullet>
          </FamilyP1>
          <FamilyButton
            mt="60px"
            onClick={() => setFamilyAction(FAMILY_ACTIONS.ENABLE_FAMILY)}
          >
            Join The Family
          </FamilyButton>
        </Flex>
      </Flex>
      <Flex bg="familyYellow" flexDirection="column">
        <CircularBackground height="62px" />
        <Flex
          py={{ _: "30px", md: "48px" }}
          pl={{ _: "30px", md: "48px" }}
          pr={{ _: "30px", md: 0 }}
          flexDirection={{ _: "column", md: "row" }}
        >
          <Flex flexDirection="column" flex="1" order={{ _: 2, md: 1 }}>
            <BjolaH1 color="familyTeal">The STEEZY Experience</BjolaH1>
            <FamilyP1 color="familyTeal">
              With your subscription, you&apos;ll get unlimited access to the
              STEEZY App, which includes:
              <br />
              <br />
              <FamilyBullet>
                STEEZY Studio: 1500+ dance classes, workouts, and more,
                featuring 150+ world-class dance instructors
              </FamilyBullet>
              <FamilyBullet>
                STEEZY Family: Our curated family-friendly dance experience,
                with 50+ Dance Alongs and counting
              </FamilyBullet>
              <br />
              Jump in anytime, anywhere on your computer, mobile device, or cast
              to your TV via AirPlay or Chromecast!
            </FamilyP1>
            <FamilyButtonWhite
              mt="60px"
              onClick={() => setFamilyAction(FAMILY_ACTIONS.ENABLE_FAMILY)}
            >
              Join The Family
            </FamilyButtonWhite>
          </Flex>
          <Flex flex="1.75" order={{ _: 1, md: 2 }}>
            <Img
              src={`${env("PUBLIC_ASSET_PATH")}/family/family-action.png`}
              width="100%"
              height="auto"
              objectFit="contain"
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        justifyContent={{ _: "start", md: "space-between" }}
        p="32px"
        flexDirection={{ _: "column", md: "row" }}
      >
        <Icon
          as={STEEZYFamilyVertical}
          maxWidth={{ _: "70px", md: "137px" }}
          width="100%"
          height="100%"
        />
        <Flex
          alignItems={{ _: "star", md: "center" }}
          flexDirection={{ _: "column", md: "row" }}
          mt={{ _: "20px", md: 0 }}
          justifyContent="right"
          flexWrap="wrap"
        >
          <FamilyH2>Join our STEEZY Fam today!</FamilyH2>
          <FamilyButton
            mt={{ _: "20px", md: 0 }}
            maxWidth={{ _: "100%", md: "220px" }}
            onClick={() => setFamilyAction(FAMILY_ACTIONS.ENABLE_FAMILY)}
          >
            Get Started
          </FamilyButton>
        </Flex>
      </Flex>
      <Flex flexDirection="column" px={{ _: "30px", md: "48px" }}>
        <Flex justifyContent={{ _: "space-between", md: "end" }} width="100%">
          <FamilyA href={`${env("PUBLIC_URL")}`} target="blank">
            STEEZY Studio
          </FamilyA>
          <FamilyA href={`${env("PUBLIC_URL")}/terms`} target="blank">
            Terms of Use
          </FamilyA>
          <FamilyA href={`${env("PUBLIC_URL")}/privacy`} target="blank">
            Privacy Policy
          </FamilyA>
        </Flex>
        <FamilyP3
          mt="18px"
          fontSize="8px"
          color="familyBlue"
          textAlign={{ _: "left", md: "right" }}
        >
          Copyright 2022. STEEZY Inc. All Rights Reserved
        </FamilyP3>
      </Flex>
    </Flex>
  );
}
