import React, { useEffect } from "react";
import { ValidatePinModal } from "app/components/ValidatePinModal";
import {
  useGetParentalControlsQuery,
  useUserSetParentalControlsMutation,
} from "services/graphql";
import Text from "app/components/Text";

interface Props {
  onSuccess(): void;
  onCancel(): void;
}

export const DisableFamilyFlow = ({ onSuccess, onCancel }: Props) => {
  const { data: userData } = useGetParentalControlsQuery();
  const isParentalControlsEnabled = userData?.me?.parentalControls?.isEnabled;
  const [setParentalControlsMutation] = useUserSetParentalControlsMutation();

  useEffect(() => {
    if (!isParentalControlsEnabled) {
      onSuccess();
    }
  }, [isParentalControlsEnabled]);

  return (
    <>
      <ValidatePinModal
        isOpen
        onValidated={(pin: string) => {
          setParentalControlsMutation({
            variables: {
              input: {
                currentPin: pin,
                isEnabled: false,
              },
            },
          });
        }}
        onCancel={onCancel}
      >
        <Text fontWeight="700" fontSize="63px">
          Enter your PIN
        </Text>
        <Text my="64px" fontWeight="400" fontSize="14px">
          To turn STEEZY Family off, enter your PIN.
        </Text>
      </ValidatePinModal>
    </>
  );
};
