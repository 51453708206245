import React, { useState } from "react";
import Icon, { STEEZYFamilyVertical, ChevronRight } from "app/components/Icon";
import Flex from "app/components/Flex";
import Text from "app/components/Text";
import { useGetParentalControlsQuery } from "services/graphql";
import { familyManageFamilyMode } from "services/typewriter/segment";
import {
  ANALYTICS_ACTION,
  ANALYTICS_LOCATION,
  ANALYTICS_MODULE,
} from "constants/index";
import { useFamilyModeSetting } from "hooks/useFamilyModeSetting";
import { AccountBodyWrapper } from "../AccountBodyWrapper";
import { FlexLink, TextLink } from "./styles";
import { UpdatePinFlow } from "./UpdatePinFlow";
import { DisableFamilyFlow } from "./DisableFamilyFlow";
import { EnableFamilyFlow } from "./EnableFamilyFlow";
import { FAMILY_ACTIONS } from "./constants";
import { LandingPage } from "./LandingPage";

export default () => {
  const [familyAction, setFamilyAction] = useState("");

  const { data: userData } = useGetParentalControlsQuery();
  const isParentalControlsEnabled = userData?.me?.parentalControls?.isEnabled;
  const { setAnalyticsContext } = useFamilyModeSetting();

  const resetFamilyAction = () => setFamilyAction("");

  return (
    <>
      <AccountBodyWrapper
        containerProps={
          !isParentalControlsEnabled &&
          ({
            px: 0,
            py: 0,
            pt: 0,
            maxWidth: "100%",
          } as any)
        }
      >
        <Flex alignItems="center" flexDirection="column">
          {isParentalControlsEnabled ? (
            <>
              <Icon width="185px" height="185px" as={STEEZYFamilyVertical} />
              {/* TODO: Update with correct intro title */}
              <Text fontSize="14px" fontWeight="700" lineHeight="110%" mb={6}>
                Manage your Family Account
              </Text>
              <FlexLink
                onClick={() => setFamilyAction(FAMILY_ACTIONS.UPDATE_PIN)}
              >
                <TextLink>Manage Your Pin</TextLink>
                <ChevronRight height="12px" />
              </FlexLink>
              <FlexLink
                mt={4}
                onClick={() => setFamilyAction(FAMILY_ACTIONS.DISABLE_FAMILY)}
              >
                <TextLink>Turn Steezy Family Off</TextLink>
                <ChevronRight height="12px" />
              </FlexLink>
            </>
          ) : (
            <LandingPage setFamilyAction={setFamilyAction} />
          )}
        </Flex>
      </AccountBodyWrapper>
      {familyAction === FAMILY_ACTIONS.UPDATE_PIN && (
        <UpdatePinFlow
          onSuccess={() => {
            familyManageFamilyMode({
              action: ANALYTICS_ACTION.updatePin,
              module: ANALYTICS_MODULE.update_pin,
              location: ANALYTICS_LOCATION.profile,
            });
            resetFamilyAction();
          }}
          onCancel={resetFamilyAction}
        />
      )}
      {familyAction === FAMILY_ACTIONS.DISABLE_FAMILY && (
        <DisableFamilyFlow
          onSuccess={() => {
            familyManageFamilyMode({
              action: ANALYTICS_ACTION.disablePin,
              module: ANALYTICS_MODULE.disable_pin,
              location: ANALYTICS_LOCATION.profile,
            });
            resetFamilyAction();
          }}
          onCancel={resetFamilyAction}
        />
      )}
      {familyAction === FAMILY_ACTIONS.ENABLE_FAMILY && (
        <EnableFamilyFlow
          onSuccess={() => {
            familyManageFamilyMode({
              action: ANALYTICS_ACTION.setupPin,
              module: ANALYTICS_MODULE.setup_pin,
              location: ANALYTICS_LOCATION.profile,
            });
            setAnalyticsContext(ANALYTICS_LOCATION.profile);
            resetFamilyAction();
          }}
          onCancel={resetFamilyAction}
        />
      )}
    </>
  );
};
