import React from "react";
import { CreatePinModal } from "../CreatePinModal";

interface Props {
  onSuccess(): void;
  onCancel(): void;
}

export const EnableFamilyFlow = ({ onSuccess, onCancel }: Props) => (
  <CreatePinModal isOpen onSuccess={onSuccess} onCancel={onCancel} />
);
