import Button from "app/components/Button";
import styled from "styled-components";
import { H1, P } from "app/components/Typography";
import { darken } from "polished";
import { size } from "styled-system";

export const Video = styled.video`
  overflow: hidden;
  object-fit: cover;
  width: 100%;
`;

export const BjolaH1 = styled(H1)`
  font-family: "BjolaFixed";
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;

  ${({ theme }) => theme.mediaQueries.gtmd} {
    font-size: 48px;
  }
`;

export const FamilyP1 = styled(P)`
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.01em;
`;

export const FamilyP2 = styled(P)`
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.01em;
`;

export const FamilyP3 = styled(P)`
  font-weight: 700;
  font-size: 8px;
  line-height: 16px;
  letter-spacing: 0.01em;
`;

export const FamilyH2 = styled.h2`
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.familyBlue};
  max-width: 118px;

  ${({ theme }) => theme.mediaQueries.gtmd} {
    margin: 0px 16px;
    max-width: 100%;
    font-size: 24px;
    line-height: 36px;
    white-space: nowrap;
  }
`;

export const FamilyA = styled.a`
  font-weight: 700;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.familyBlue};
  ${({ theme }) => theme.mediaQueries.gtmd} {
    margin-left: 24px;
  }
`;

export const FamilyButton = styled(Button)`
  font-family: "BjolaFixed";
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  letter-spacing: 0.01em;
  background: ${({ theme }) => theme.colors.familyOrange};
  height: 43px;
  width: 100%;
  border-radius: 120px;

  ${({ theme }) => theme.mediaQueries.gtmd} {
    height: 72px;
    font-size: 20px;
  }

  @media (hover: hover) {
    :hover {
      background: ${({ theme }) => darken(0.1, theme.colors.familyOrange)};
    }
  }

  ${size}
`;

export const FamilyButtonWhite = styled(FamilyButton)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.familyOrange};

  @media (hover: hover) {
    :hover {
      background: ${({ theme }) => darken(0.1, theme.colors.white)};
    }
  }
`;

export const FamilyBullet = styled.li`
  list-style-type: disc;
`;
