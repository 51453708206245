import styled from "styled-components";
import Flex from "app/components/Flex";
import Text from "app/components/Text";

export const FlexLink = styled(Flex).attrs({
  cursor: "pointer",
  width: "100%",
  maxWidth: "285px",
  alignItems: "center",
  justifyContent: "space-between",
})``;

export const TextLink = styled(Text).attrs(props => ({
  fontWeight: 500,
  fontSize: "14px",
  color: "monochrome.8",
  ...props,
}))``;
